<template>
  <div>
    <a-form layout="inline" :model="searchFormState" style="margin-bottom: 20px">
      <a-form-item label="标题">
        <a-input
          v-model:value="searchFormState.advertisementTitle"
          placeholder="标题"
          class="searchInputWidth"
        ></a-input>
      </a-form-item>

      <a-form-item style="margin-left: 20px">
        <a-button type="primary" @click="search(searchFormState)" style="border-radius: 4px">
          <template #icon>
            <search-outlined />
          </template>
          查询
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="clearFormState(searchFormState)">
          <template #icon>
            <redo-outlined />
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="mb-20">
      <a-button type="primary" @click="operate('add')">
        <template #icon>
          <plus-outlined />
        </template>
        新增
      </a-button>

      <!-- <a-button @click="batchOperate('delete')" class="ml20">
        <template #icon></template>
        批量删除
      </a-button>-->
    </div>

    <!-- 表格数据展示 -->
    <a-table
      :columns="columns"
      :data-source="tabaleData"
      bordered
      :pagination="false"
      :rowKey="(item) => (item.srAdvertisementId)"
    >
      <template #curriculumImage="{ record }">
        <a-image
          :height="30"
          :width="30"
          style="overflow: hidden;"
          :src="`http://114.116.236.37:9001/${record.advertisementUrl}`"
        />
      </template>
      <template #advertisementStatus="{ record }">
        <!-- <a-switch
          :checked="record.advertisementStatus === 0"
          checked-children="上架"
          un-checked-children="下架"
          default-checked
          @change="simpleStopUse(record)"
        /> -->
        <a-popconfirm
          :title="`确认${record.advertisementStatus ? '上架' : '下架' }`"
          ok-text="确认"
          cancel-text="取消"
          @confirm="simpleStopUse(record)"
        >
        <a-switch
          :checked="record.advertisementStatus === 0"
          checked-children="上架"
          un-checked-children="下架"
        />
        </a-popconfirm>
      </template>
      <template #operation="{ record }">
        <a style="color: #637cfe" @click="operate('edit', record)">编辑</a>
        <a-divider type="vertical" />
        <!-- <a style="color: #637cfe" @click="operate('delete', record)">删除</a> -->
        <a-popconfirm title="确认删除?" @confirm="operate('delete', record)">
          <a href="#">删除</a>
        </a-popconfirm>
      </template>
    </a-table>

    <div
      style="
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      "
    >
      <a-pagination
        v-model:current="pagination.current"
        :total="pagination.total"
        v-if="tabaleData.length"
        @change="paginationChange"
        v-model:pageSize="pagination.pageSize"
        show-size-changer
        @showSizeChange="onShowSizeChange"
        :page-size-options="pagination.pageSizeOptions"
        :show-total="(total) => `共 ${total} 条`"
        show-quick-jumper
      />
    </div>

    <modal
      ref="customDialog"
      :title="(modalState ? '新增' : '编辑') + '广告'"
      :width="780"
      @handleOk="sumbitEditForm(uploadState.fileList)"
    >
      <a-form
        name="custom-validation"
        ref="formRef"
        :model="formState"
        :rules="formStateRules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="标题" name="advertisementTitle">
          <a-input
            v-model:value="formState.advertisementTitle"
            autocomplete="off"
            style="width: 400px"
            policeholder="请输入账号"
          />
        </a-form-item>
        <a-form-item label="图片" extra="上传格式为：JPG和PNG；推荐图片尺寸为：343X147" name="headPortrait" class="curriculumImg">
          <a-upload
            v-model:file-list="uploadState.fileList"
            :action="uploadState.fileurl"
            list-type="picture-card"
            name="mfile"
            :data="{ type: 1 }"
          >
            <div v-if="uploadState.fileList.length < 1">
              <plus-outlined />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <span
            class="uptext"
            v-if="uploadState.imgFile && uploadState.fileList.length === 0"
          >请选择广告图片</span>
        </a-form-item>
      </a-form>
    </modal>
  </div>
</template>

<script>
import {
  SearchOutlined,
  RedoOutlined,
  PlusOutlined
} from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, onMounted } from "vue";
import Modal from "@/components/modal.vue";
import { myPost, myGet } from "@/common/request.js";
import { message } from "ant-design-vue";

const api = {
  // 查询
  queryAdvertisement:
    "/xkcwebApi/srAdvertisement/querySrAdvertisementPageList.htm",
  //新增
  updateAdvertisement: "/xkcwebApi/srAdvertisement/addSrAdvertisement.htm",
  // 编辑
  editAdvertisement: "/xkcwebApi/srAdvertisement/updateSrAdvertisement.htm",
  // 删除
  delAdvertisement: "/xkcwebApi/srAdvertisement/deleteSrAdvertisementById.htm",
  // 批量删除
  batchAdvertisement:
    "/xkcwebApi/srAdvertisement/deleteSrAdvertisementByIds.htm",
  // 上架、下架
  upAdvertisement: "/xkcwebApi/srAdvertisement/updateStatusParam.htm",
  // 上传图片
  updatePicture: "/api/common/upload/upload.htm"
};

export default defineComponent({
  components: {
    SearchOutlined,
    RedoOutlined,
    PlusOutlined,
    Modal
  },
  setup() {
    const customDialog = ref(null);

    const searchFormState = reactive({
      advertisementTitle: null,
      roleId: null
    });

    // 搜索查询
    const search = () => {
      queryList(1, searchFormState);
    };

    const formStateRules = {
      advertisementTitle: [
        {
          required: true,
          message: "标题不能为空",
          trigger: ["blur", "change"],
          whitespace: true
        }
      ]
    };

    // 重置
    const clearFormState = (form, type) => {
      uploadState.fileList = [];
      for (var i in form) {
        form[i] = null;
      }
      type != "handle" && queryList(1);
    };

    const rolesOptions = ref([]);

    const modalState = ref(true);

    let formState = reactive({
      advertisementTitle: null,
      srAdvertisementId: null,
      id: ""
    });

    let uploadState = reactive({
      imgFile: false,
      // fileurl: `http://124.70.211.62:8093${api.updatePicture}`,
      fileurl: `http://114.116.236.37:8094${api.updatePicture}`,
      fileList: [
        {
          url: "",
          uid: "-1"
        }
      ]
    });
    const columns = [
      {
        title: "标题",
        dataIndex: "advertisementTitle",
        key: "advertisementTitle",
        align: "center"
      },
      {
        title: "广告图片",
        dataIndex: "curriculumImage",
        key: "advertisementUrl",
        align: "center",
        slots: {
          customRender: "curriculumImage"
        }
      },
      {
        title: "维护时间",
        dataIndex: "updateTime",
        align: "center",
        slots: {
          customRender: true
        }
      },
      {
        title: "当前状态",
        dataIndex: "advertisementStatus",
        align: "center",
        slots: {
          customRender: "advertisementStatus"
        }
      },
      {
        title: "操作",
        dataIndex: "operation",
        slots: {
          customRender: "operation"
        },
        align: "center"
      }
    ];

    onMounted(() => {
      queryList(1);
    });
    // 表格数据
    const tabaleData = ref([]);
    tabaleData.value = [
      {
        advertisementTitle: "",
        advertisementUrl: "",
        maintainTime: null,
        srAdvertisementId: 1
      }
    ];

    const queryList = (cur, condition = {}) => {
      if (cur) {
        pagination.value.current = cur;
      }
      let param = {
        page: pagination.value.current,
        size: pagination.value.pageSize,
        ...condition
      };
      myPost(api.queryAdvertisement, param)
        .then(res => {
          if (res.code === 200) {
            tabaleData.value = res.data;
          }
          pagination.value.total = res.total;
        })
        .catch(err => {
          console.log(err);
        });
    };

    const formRef = ref("");
    const operate = (type, record) => {
      formRef.value && formRef.value.resetFields();
      clearFormState(formState, "handle");
      modalState.value = type == "add" ? true : false;
      // 编辑
      if (type == "edit") {
        formRef.value && formRef.value.resetFields();
        clearFormState(formState, "handle");
        formState.advertisementTitle = record.advertisementTitle;
        formState.srAdvertisementId = record.srAdvertisementId;
        uploadState.fileList = [
          {
            uid: "-1",
            url: `http://114.116.236.37:9001/${record.advertisementUrl}`
          }
        ];
        customDialog.value.showModal();
      } else if (type == "add") {
        formRef.value && formRef.value.resetFields();
        clearFormState(formState, "handle");
        customDialog.value.showModal();
      } else if (type == "delete") {
        myGet(api.delAdvertisement, { id: record.srAdvertisementId }).then(
          res => {
            message.success(res.message);
            queryList("", searchFormState);
          }
        );
      }
    };

    //新增确定
    const sumbitEditForm = file => {
      formRef.value
        .validate()
        .then(() => {
          if (file.length !== 0) {
            if (modalState.value) {
              myPost(api.updateAdvertisement, {
                advertisementTitle: formState.advertisementTitle,
                advertisementUrl: file[0].response.data[0].path
              })
                .then(res => {
                  if (res.code === 200) {
                    message.success("新增成功");
                    queryList("", searchFormState);
                    customDialog.value.closeModal();
                  }
                })
                .catch(err => {
                  console.log(err);
                });
            } else {
              // debugger
              // eslint-disable-next-line no-prototype-builtins
              let advertisementFile = file[0].hasOwnProperty("response")
                ? file[0].response.data[0].path
                : file[0].url.slice(
                    file[0].url.indexOf("/xingkecheng/") + 1,
                    file[0].url.length
                  );
              myPost(api.editAdvertisement, {
                advertisementTitle: formState.advertisementTitle,
                advertisementUrl: advertisementFile,
                id: formState.srAdvertisementId
              })
                .then(res => {
                  customDialog.value.closeModal();
                  message.success(res.message);
                  queryList("", searchFormState);
                })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            uploadState.imgFile = true;
          }
        })
        .catch(err => {
          console.log(err);
        });
    };

    const simpleStopUse = record => {
      console.log(record.switch);
      myPost(api.upAdvertisement, {
        id: record.srAdvertisementId,
        advertisementStatus: record.advertisementStatus ? 0 : 1
      }).then(res => {
        message.success(res.message);
        queryList(1, searchFormState);
      });
    };

    // 分页
    const pagination = ref({
      total: 50,
      current: 1,
      pageSize: 10,
      pageSizeOptions: ["10", "20", "30", "40", "50"]
    });

    const paginationChange = (page, pageSize) => {
      pagination.value.page = page;
      pagination.value.pageSize = pageSize;
    };
    const onShowSizeChange = (page, pageSize) => {
      pagination.value.page = page;
      pagination.value.pageSize = pageSize;
    };

    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      searchFormState,
      rolesOptions,
      customDialog,
      modalState,
      formStateRules,
      sumbitEditForm,
      search,
      formRef,
      clearFormState,
      uploadState,
      formState,
      tabaleData,
      columns,
      paginationChange,
      onShowSizeChange,
      pagination,
      operate,
      // beforeUpload
      simpleStopUse
    };
  }
});
</script>

<style lang="scss" scoped>
.searchInputWidth {
  width: 220px;
}
.ml20 {
  margin-left: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}

.curriculumImg {
  position: relative;
}
.curriculumImg::before {
  position: absolute;
  left: 78px;
  top: 13px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.uptext {
  color: #ff4d4f;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
}
.dropbox {
  width: 400px;
  height: 180px;
  line-height: 1.5;
}
.ant-upload-text {
  margin-top: 8px;
  color: #bfbfbf;
}
::v-deep .ant-upload.ant-upload-select-picture-card {
  width: 400px;
}
</style>